import { createApp } from 'vue'
import App from './App.vue'

document.addEventListener("turbo:load", () => {

  document.querySelectorAll('#company-select-app').forEach(function(mountEl) {
    const app = createApp(App, { ...mountEl.dataset})
    app.mount("#"+mountEl.id)
  });


  function nestedContactSelectApp(elm) {
    elm.querySelectorAll('.company-select-app').forEach(function(mountEl) {
      const app = createApp(App, { ...mountEl.dataset, inputElement: elm.querySelector(".crm_contact_id").name })
      app.mount(mountEl)
    });
  }

  document.querySelectorAll('.nested-contact-container').forEach(function(mountEl) {
    mountEl.addEventListener('cocoon:after-insert', function(e) {
      nestedContactSelectApp(e.detail[2])
    });
  });

  document.querySelectorAll('.nested-contact-fields').forEach(function(mountEl) {
    nestedContactSelectApp(mountEl)
  })

});

